<template lang="pug">
include ../../../configs/template
div.row(v-if="sailorDocument")
  div.col-lg-6.text-left
    +data-info('number', 'sailorDocument.number')
  div.col-lg-6.text-left
    +data-info('port', 'sailorDocument.port[nameLang]')
  div.col-lg-6.text-left
    +data-info('payment', 'sailorDocument.is_payed ? $t("isPayed") : $t("notPayed")')
  div(v-if="sailorDocument.type_receipt").col-lg-6.text-left
    +data-info('receiveDoc', 'getDirectoryObject({ value: "processingStatuses", id: sailorDocument.type_receipt})?.[nameLang]')
  div.col-lg-12.text-left(v-if="!sailorDocument.is_continue")
    +data-info('blankPayment', 'sailorDocument.is_payed_blank ? $t("isPayed") : $t("notPayed")')
  div.col-lg-12.text-left(v-if="sailorDocument.sailor_passport")
    +data-info-link('sailorPassport', 'sailorPassport', 'linkSailorPassport')(class="text-primary")
  div.col-lg-4.text-left
    +data-info('createDate', 'sailorDocument.date_create')
  div.col-lg-4.text-left
    +data-info('dateModified', 'sailorDocument.date_modified')
  div.col-lg-4.text-left
    +data-info('dataEvent', 'sailorDocument.date_meeting')
  div.col-lg-4.text-left
    +data-info-status('status', 'sailorDocument.status_document[nameLang]', 'getStatus(sailorDocument.status_document.id)')
  div(v-if="sailorDocument.comments?.length").col-sm-12.text-left
    h5.p-0.m-0 {{$t('rejectionReasonStatement')}}:
    v-divider
    div(v-for="(comment, index) in sailorDocument.comments" :key="comment.id").ml-4
      div
        +data-info('rejectionReasonStatement','getDirectoryObject({ id: comment.additional_info.reason, value: "rejectionReasons" })[nameLang]')
      div(v-if="checkAccess('backOffice')").pt-2.py-3
        +data-info('createdBy', 'comment.created_by.name')
      div.pt-2.py-3
        +data-info('dateCreate', 'new Date(comment.created_by.date).toLocaleString()')
      div.pt-2.py-3
        +data-info('reasonComment', 'comment.comment')
      v-divider(v-if="index !== sailorDocument.comments.length-1")
</template>

<script>
import { getStatus } from '@/mixins/main'
import { mapActions, mapGetters, mapState } from 'vuex'
import { checkAccess } from '@/mixins/permissions'

export default {
  name: 'SailorPassportStatementInfo',
  props: {
    comments: { type: Array, default: () => ([]) },
    sailorDocument: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      checkAccess,
      getStatus,
      rejectStatusId: 71
    }
  },
  computed: {
    ...mapState({
      nameLang: state => state.main.lang === 'en' ? 'name_eng' : 'name_ukr',
      sailorPassportById: state => state.sailor.sailorPassportById
    }),
    ...mapGetters(['getDirectoryObject', 'statusById', 'rejectionReasonByID']),
    linkSailorPassport () {
      const { id } = this.$route.params
      return `/sailor/${id}/passports/sailors/${this.sailorDocument.sailor_passport}`
    },
    sailorPassport () {
      return `№ ${this.sailorPassportById.number_document}`
    }
  },
  mounted () {
    this.sailorDocument.sailor_passport && this.getSailorPassportById({ id: this.$route.params.id, documentID: this.sailorDocument.sailor_passport })
  },
  methods: {
    ...mapActions(['getSailorPassportById'])
  }

}

</script>
